import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Clients = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityClients {
        edges {
          node {
            title
            image {
              asset {
                fluid(maxWidth: 180) {
                  ...GatsbySanityImageFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="carousel_wrapper animation--fade-up animation">
      <div className="scroll_block">
        <div className="client_wrapper">
          <div className="custom_opacity_left"></div>
          <div className="custom_opacity_right"></div>
          {data.allSanityClients.edges.map(function(edge) {
            return (
              <Img
                className="client"
                fluid={edge.node.image.asset.fluid}
                alt={edge.node.title}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Clients
