import React, { useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"
import Clients from "../components/clients"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"
import PageOpening from "../components/pageOpening"
const IndexPage = () => {
  useEffect(() => {
    sliderAnimation()
    getClients()
  })
  const data = useStaticQuery(graphql`
    query {
      sanityPages(id: { eq: "5b1d9a43-6dce-54d1-9711-66cd8a530cff" }) {
        seo {
          seo_title
          meta_description
        }
        title
        _rawBody
        _rawSubtitle
        templateList
        mainImage {
          alt
          video
          image {
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }

      allSanityServices(sort: { fields: publishedAt, order: ASC }) {
        edges {
          node {
            title
            subtitle
            slug {
              current
            }
          }
        }
      }
      sanitySlider {
        _rawSlide1
        _rawSlide2
        _rawSlide3
        title
      }
      allSanityPost(sort: { order: ASC, fields: publishedAt }, limit: 1) {
        edges {
          node {
            title
            subTitle
            _rawBody
            mainImage {
              image {
                asset {
                  fluid(maxWidth: 900) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              alt
            }
            slug {
              current
            }
          }
        }
      }
      allSanityCases(
        limit: 1
        filter: { released: { eq: true } }
        sort: { fields: publishedAt, order: ASC }
      ) {
        edges {
          node {
            title
            subTitle
            _rawBody
            slug {
              current
            }
            mainImage {
              image {
                asset {
                  fluid(maxWidth: 900) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      {data.sanityPages.seo && (
        <SEO
          title={data.sanityPages.seo.seo_title}
          metaDescription={data.sanityPages.seo.meta_description}
          seoTitle={data.sanityPages.seo.seo_title}
        />
      )}

      <section className="index">
        <PageOpening data={data} />

        <Clients></Clients>

        <div className="insights_wrapper_frontpage animation--fade-up animation">
          {data.allSanityCases.edges.map(function(cases) {
            return (
              <Link to={`/${cases.node.slug.current}/`}>
                <div className="single_insight ">
                  <p className="category_mobile">Case</p>
                  <Img
                    fluid={cases.node.mainImage.image.asset.fluid}
                    alt={data.sanityPages.mainImage.alt}
                  />

                  <div className="right_wrapper">
                    <p className="category_desktop">Case</p>
                    <h2>{cases.node.title}</h2>

                    <Link
                      className="blue_button"
                      to={`/${cases.node.slug.current}/`}
                    >
                      Læs mere
                    </Link>
                  </div>
                </div>
              </Link>
            )
          })}
          {data.allSanityPost.edges.map(function(news) {
            return (
              <Link to={`/${news.node.slug.current}/`}>
                <div className="single_insight ">
                  <div className="left_wrapper">
                    <p className="category_mobile">Blog</p>
                    <Img
                      fluid={news.node.mainImage.image.asset.fluid}
                      alt={data.sanityPages.mainImage.alt}
                    />
                  </div>
                  <div className="right_wrapper">
                    <p className="category_desktop">Blog</p>
                    <h2>{news.node.title}</h2>

                    <Link
                      className="blue_button"
                      to={`/${news.node.slug.current}/`}
                    >
                      Læs mere
                    </Link>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>

        <div className="service_wrapper animation--fade-up animation">
          {data.allSanityServices.edges.map(function(service) {
            return (
              <Link to={`/${service.node.slug.current}/`}>
                <div className="single_service">
                  <h2>{service.node.title}</h2>
                  <p>{service.node.subtitle}</p>

                  <Link to={`/${service.node.slug.current}/`}>Læs mere</Link>
                </div>
              </Link>
            )
          })}
        </div>

        <div className={data.sanityPages.templateList}>
          <BlockContent
            blocks={data.sanityPages._rawBody}
            projectId="ppd47c0u"
            dataset="production"
            serializers={serializers}
          />
        </div>
        <h2 className="slide_header animation--fade-up animation">
          {data.sanitySlider.title}
        </h2>

        <div className="slider animation--fade-up animation">
          <div className="meassure"></div>
          <div className="text_content">
            <div className="slide animation">
              <span className="number">1</span>

              <BlockContent
                blocks={data.sanitySlider._rawSlide1}
                projectId="ppd47c0u"
                dataset="production"
                serializers={serializers}
              />
            </div>
            <div className="slide animation--fade-up animation">
              <span className="number">2</span>

              <BlockContent
                blocks={data.sanitySlider._rawSlide2}
                projectId="ppd47c0u"
                dataset="production"
                serializers={serializers}
              />
            </div>
            <div className="slide animation--fade-up animation">
              <span className="number">3</span>

              <BlockContent
                blocks={data.sanitySlider._rawSlide3}
                projectId="ppd47c0u"
                dataset="production"
                serializers={serializers}
              />
            </div>
          </div>
        </div>
      </section>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default IndexPage

function sliderAnimation() {
  if (window.innerWidth < 767) {
    // callback function to do animations
    const scrollImations = (entries, observer) => {
      entries.forEach(entry => {
        // only do animation if the element is fully on screen
        if (entry.intersectionRatio > 0) {
          entry.target.style.opacity = "0.5"
          entry.target.querySelector("span").style.opacity = "0.2"
          entry.target.querySelector("span").classList.remove("expand")
        }
        if (entry.intersectionRatio >= 1) {
          entry.target.style.opacity = "1"
          entry.target.querySelector("span").classList.add("expand")
        }
      })
    }

    const options = {
      threshold: 1,
      rootMargin: "00px",
    }

    // create the observer

    const observer = new IntersectionObserver(scrollImations, options)

    // target the elements to be observed
    const slides = document.querySelectorAll(".slide")
    slides.forEach(slide => {
      observer.observe(slide)
    })
  } else {
    // callback function to do animations
    const scrollImations = (entries, observer) => {
      entries.forEach(entry => {
        // only do animation if the element is fully on screen
        if (entry.intersectionRatio < 1) {
          entry.target.style.opacity = "0.5"
          entry.target.querySelector("span").style.opacity = "0.2"
          entry.target.querySelector("span").classList.remove("expand")
        }
        if (entry.intersectionRatio >= 1) {
          entry.target.style.opacity = "1"
          entry.target.querySelector("span").classList.add("expand")
        }
      })
    }

    const options = {
      threshold: 1,
      rootMargin: "-130px",
    }

    // create the observer

    const observer = new IntersectionObserver(scrollImations, options)

    // target the elements to be observed
    const slides = document.querySelectorAll(".slide")
    slides.forEach(slide => {
      observer.observe(slide)
    })
  }
}

let allClients = []
let firstClient = ""
let firstcln = ""

function getClients() {
  if (window.innerWidth > 1200) {
    // const slider = document.querySelector(".scroll_block")

    allClients = document.querySelectorAll(".client")

    firstClient = allClients[0]

    firstcln = firstClient.cloneNode(true)

    let clientWrapper = ""
    clientWrapper = document.querySelector(".client_wrapper")

    setTimeout(() => {
      clientWrapper.style.transition = "0.2s ease-out"
      clientWrapper.style.marginLeft = "-216px"

      clientWrapper.ontransitionend = () => {
        clientWrapper.style.transition = "0s"
        clientWrapper.style.marginLeft = "0"
        firstClient.parentNode.removeChild(firstClient)

        increaseNumber(clientWrapper, firstcln)
      }
    }, 3500)
  }
}

function increaseNumber(clientWrapper, firstcln) {
  clientWrapper.appendChild(firstcln)

  resetclients()
}
function resetclients() {
  allClients = []

  firstClient = ""

  firstcln = ""

  getClients()
}
